<template>
	<ul class="insights__filter">
		<template v-if="insightsList && filterTypeList">
			<li v-for="item in filterTypeList" :key="item" @click="pickedCategory = item; updateUrl();" class="insights__filter-item" :class=" pickedCategory == item ? 'insights__filter-item--active' : ''">{{ item == '*' ? 'All' : item }}</li>
		</template>
		<template v-else>
			<li class="insights__filter-item insights__filter-item--skeleton">All</li>
			<li class="insights__filter-item insights__filter-item--skeleton">Cases</li>
			<li class="insights__filter-item insights__filter-item--skeleton">Blog</li>
			<li class="insights__filter-item insights__filter-item--skeleton">News</li>
		</template>
	</ul>
	<div class="insights__list" data-json-url="@jsonUrl">
		<template v-if="insightsList">
			<div v-for="(item, index) in filteredInsightsList" :key="item.link" class="insights__item" :data-date="item.date">
				<a :href="item.link" class="insights__link">
					<div class="insights__image" :style="'background-image: url(' + getItemImage(item.image) + ')'"></div>
					<div class="insights__info">
						<div class="insights__type">{{ item.type.toLowerCase() == 'cases' ? 'Case' : item.type }}</div>
						<div class="insights__heading">{{ item.heading }}</div>
						<div class="link">
							<span class="link-underline">Read more</span>
						</div>
					</div>
				</a>
			</div>
		</template>
		<template v-else>
			<div v-for="n in parseInt(pageSize)" class="insights__item insights__item--skeleton"></div>
		</template>
	</div>
	<div v-if="insightsList" class="link insights__load-more" @click="page++"><a class="link-button" :class="(pickedCategory == '*' && insightsList && insightsList.length > 8) || (pickedCategory != '*' && filteredInsightsList && filteredInsightsList.length > 8) ? '' : 'disabled'">Load more</a></div>
	<div v-else class="link insights__load-more insights__load-more--skeleton"><a class="link-button">&nbsp;</a></div>
</template>
<script>
	export default {
		name: 'Insights list',
		props: {
			pageSize: {
				type: Number,
				default: ''
			},
			jsonUrl: {
				type: String,
				default: null
			}
		},
		computed: {
			filteredInsightsList() {
				if (this.insightsList) {
					let newList = this.insightsList.filter(x => x.type == this.pickedCategory || this.pickedCategory == '*');
					newList = newList.filter((x, index) => (index < (this.pageSize * this.page)));

					return newList;
				}
			},
		},
		mounted() {
			if (this.jsonUrl != null) {
				const fetches = [];
				const typeSet = new Set();

				fetches.push(
					fetch(this.jsonUrl)
						.then(response => response.json())
						.then(data => {
							insightsData = data.results;

							typeSet.add('*');

							insightsData.forEach(dataItem => {
								typeSet.add(dataItem.type);
							});

							insightsData = insightsData.sort(function(a,b){
								return new Date(b.date) - new Date(a.date);
							});
						}));

				const _this = this;

				Promise.all(fetches).then(function() {
					_this.insightsList = insightsData;
					_this.filterTypeList = Array.from(typeSet);
				});
			}
		},
		data() {
			return {
				pickedCategory: window.location.hash.replace('#','') || '*',
				pageSize: this.pageSize,
				page: 1,
				insightsList: null,
				filterTypeList: null,
				fallbackImage: '@GetString("Item.FallbackImage")'
			}
		},
		methods: {
			updateUrl() {
				window.location.hash = this.pickedCategory != '*' ? this.pickedCategory : '';
			},
			getItemImage(imagePath) {
				return imagePath || this.fallbackImage;
			}
		}
	}
</script>
