import { createApp } from 'vue';
import AcForm from '../vue/components/ac-form.vue';
import InsightsList from '../vue/components/insights-list.vue';
import store from '../vue/store';

export function setupVue() {
    const app = createApp({
        components: {
            AcForm,
            InsightsList
        },
        created() {
            store.dispatch('translationStore/fetchTranslations');
        }
    });

    // We need this to make sure "white-space: pre-line" still works
    app.config.compilerOptions.whitespace = 'preserve';

    app.use(store);

    app.mount('#app');
}
