import { createStore } from 'vuex';
import translationStore from './translations';

const store = createStore({
    modules: {
        translationStore
    }
});

export default store;
