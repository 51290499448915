import { setupVue } from './components/vue';
import { onReady } from './utils/on-ready';
import { setupNews } from './components/news';
import { setupProductOverview } from './components/product-overview';
import { setupBaseScript } from './components/script';
import { setupVideos } from './components/videos';



function init() {

    setTimeout(() => {
        document.body.classList.remove('standby');
    }, 0);

    setupVue();

    onReady(() => {
        setupNews();
        setupProductOverview();
        setupBaseScript();
        setupVideos();
    });
}

init();
