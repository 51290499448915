export function setupNews() {
    (() => {
        if (window.NodeList && !NodeList.prototype.forEach) {
            NodeList.prototype.forEach = Array.prototype.forEach;
        }

        /**
         * Detect if <template> is supported
         * @return {Boolean}    True or False
         */
        const supportsTemplate = () => {
            return 'content' in document.createElement('template');
        };

        // NEWS
        const newsLoad = (list, url) => {
            const validateResponse = (response) => {
                if (!response.ok) {
                    throw Error(response.status, response.statusText);
                }

                return response;
            };
            const readResponseAsJSON = (response) => {
                return response.json();
            };
            const logResult = (result) => {
                console.log('And the results are in: \n', result);
            };
            const logError = (error) => {
                console.warn('Looks like there was a problem: \n', error);
            };

            const newsItems = (data) => {
                const items = data.results;
                const fragment = document.createDocumentFragment();

                for (let a = data.pagination.offset, al = parseInt(data.pagination.offset) + parseInt(data.pagination.count); a < al; a++) {
                    if (items[a] !== undefined) {
                        if (supportsTemplate()) {
                            const itemsClone = document.querySelector('#news').content.cloneNode(true);

                            itemsClone.querySelector('.date time').setAttribute('datetime', items[a].date);
                            itemsClone.querySelector('.date time').innerHTML = items[a].dateFormatted;
                            itemsClone.querySelector('.heading a').href = items[a].link;
                            itemsClone.querySelector('.heading a').innerHTML = items[a].heading;

                            fragment.appendChild(itemsClone);
                        } else {
                            const itemElement = document.createElement('div');
                            itemElement.className = 'item';

                            const dateElement = document.createElement('div');
                            dateElement.className = 'date';
                            itemElement.appendChild(dateElement);

                            const timeElement = document.createElement('time');
                            timeElement.className = 'date';
                            timeElement.setAttribute('datetime', items[a].date);
                            timeElement.textContent = items[a].dateFormatted;
                            dateElement.appendChild(timeElement);

                            const headingElement = document.createElement('div');
                            headingElement.className = 'heading';
                            itemElement.appendChild(headingElement);

                            const headingLinkElement = document.createElement('a');
                            headingLinkElement.href = items[a].link;
                            headingLinkElement.textContent = items[a].heading;
                            headingElement.appendChild(headingLinkElement);

                            fragment.appendChild(itemElement);
                        }
                    }
                }

                list.querySelector('.wrapper').appendChild(fragment);

                if (parseInt(data.pagination.offset) + parseInt(data.pagination.count) >= items.length) {
                    list.querySelector('.load-more').setAttribute('hidden', true);
                } else {
                    list.querySelector('[data-action="news-load-more"]').setAttribute('data-json', data.pagination.next);
                    list.querySelector('.load-more').removeAttribute('hidden');
                }

                list.querySelector('.wrapper').removeAttribute('style');

                setTimeout(() => {
                    list.classList.remove('js-loading');

                    const elements = list.querySelectorAll('.item');
                    elements.forEach((element) => {
                        element.classList.remove('js-hidden');
                    });
                }, 20);
            };

            fetch(url)
                .then(validateResponse)
                .then(readResponseAsJSON)
                .then(newsItems)
                .catch(logError);
        };

        const newsLists = document.querySelectorAll('[data-action="news-load"]');
        newsLists.forEach((list) => {
            newsLoad(list, list.getAttribute('data-json'));
        });

        const newsLoadMore = (event) => {
            const element = event.target;

            element.closest('[data-action="news-load"]').classList.add('js-loading');

            newsLoad(element.closest('[data-action="news-load"]'), element.getAttribute('data-json'));
        };
        const newsItemsButtons = document.querySelectorAll('[data-action="news-load-more"]');
        newsItemsButtons.forEach((button) => {
            button.addEventListener('click', newsLoadMore);
        });
    })();
}
