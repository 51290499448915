import axios from 'axios';

const apiService = {
    ac: {
        getForm(formId, areaId) {
            return axios.get('/api/activecampaign/getform', { params: { formId, areaId } }).then(response => response);
        },
        addContact(contact, actiondata) {
            return axios.post('/api/activecampaign/addcontact', { contact, actiondata }).then(response => response);
        }
    }
};

export default apiService;
