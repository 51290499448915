const translationStore = {
    namespaced: true,
    state() {
        return {
            translations: null
        };
    },
    getters: {
        getTranslation: state => key => state.translations.find(x => x.Key == key) ? state.translations.find(x => x.Key == key) : { Key: key, DefaultValue: key, Value: `Translation does not exist for key "${key}".` },
        getTranslations: state => keys => {
            const translations = [];

            keys.forEach(key => {
                translations.push(state.translations.find(x => x.Key == key) ? state.translations.find(x => x.Key == key) : { Key: key, DefaultValue: key, Value: `Translation does not exist for key "${key}".` });
            });

            return translations;
        },
        getTranslationsByCategory: state => category => state.translations.filter(x => x.Key.indexOf(`Translate.${category}`) != -1)
    },
    mutations: {
        mutateTranslations (state, payload) {
            state.translations = payload;
        }
    },
    actions: {
        async fetchTranslations() {
            const _this = this;

            await fetch(`/dwapi/translations/area/${document.documentElement.dataset.areaId}`)
                .then(response => response.json())
                .then(data => {
                    _this.commit('translationStore/mutateTranslations', data);
                });
        }
    }
};

export default translationStore;
