<template>
	<form v-if="!hasBeenSubmitted" ref="form" class="form" :class="{ 'form--submitting': isSubmitting }" autocomplete="off" @submit.prevent="recaptcha">
        <template v-if="isInitializing">
            <div class="form__row">
                <div class="form__field-wrap">
                    <div class="form__placeholder-input"></div>
                </div>
            </div>
            <div class="form__row">
                <div class="form__field-wrap">
                    <div class="form__placeholder-input"></div>
                </div>
            </div>
            <div class="form__row form__row--policy">
                <div class="form__field-wrap">
                    <div class="form__placeholder-checkbox"></div>
                </div>
            </div>
            <div class="form__row form__row--submit">
                <div class="form__field-wrap">
                    <div class="form__placeholder-button"></div>
                </div>
            </div>
        </template>
        <template v-else>
            <template v-for="(field, index) in filteredCFields" :key="index">
                <!-- Standard Field (type: "Text") -->
                <template v-if="field.Type === 'fullname' || field.Type === 'firstname' || field.Type === 'lastname' || field.Type === 'customer_account'">
                    <div class="_form_element _full_width form-row half">
                        <input :id="getUniqueId(field.Type)" :name="field.Type" type="text" class="form__input" :value="field.DefaultValue" placeholder=" " :title="getFieldTitle(field)" :aria-required="field.Required" :required="field.Required" />
						<label v-if="field.Header" :for="getUniqueId(field.Type)" class="form__label">{{ getFieldHeader(field) }}</label>
                    </div>
                </template>

                <!-- Standard Field (type: "Email") -->
                <template v-else-if="field.Type === 'email'">
                    <div class="_form_element _full_width form-row half">
                        <input :id="getUniqueId(field.Type)" ref="email" :name="field.Type" type="email" class="form__input" :value="field.DefaultValue" placeholder=" " :title="getFieldTitle(field)" :aria-required="field.Required" :required="field.Required" />
						<label v-if="field.Header" :for="getUniqueId(field.Type)" class="form__label">{{ getFieldHeader(field) }}</label>
                    </div>
                </template>

                <!-- Standard Field (type: "Phone") -->
                <template v-else-if="field.Type === 'phone'">
                    <div class="_form_element _full_width form-row half">
                        <input :id="getUniqueId(field.Type)" :name="field.Type" type="tel" class="form__input" :value="field.DefaultValue" placeholder=" " :title="getFieldTitle(field)" :aria-required="field.Required" :required="field.Required" />
						<label v-if="field.Header" :for="getUniqueId(field.Type)" class="form__label">{{ getFieldHeader(field) }}</label>
                    </div>
                </template>

                <!-- Custom Field (type: "Text Input") -->
                <template v-else-if="field.Type === 'text'">
					<template v-if="field.Header == 'Comment'">
						<div class="_form_element _full_width form-row">
							<input :id="getUniqueId(field.Id)" :name="field.Id" type="text" class="form__input" data-custom-field :value="field.DefaultValue" placeholder=" " :title="getFieldTitle(field)" :aria-required="field.Required" :required="field.Required" />
							<label v-if="field.Header" :for="getUniqueId(field.Id)" class="form__label">{{ getFieldHeader(field) }}</label>
						</div>
					</template>
					<template v-else>
						<div class="_form_element _full_width form-row half">
							<input :id="getUniqueId(field.Id)" :name="field.Id" type="text" class="form__input" data-custom-field :value="field.DefaultValue" placeholder=" " :title="getFieldTitle(field)" :aria-required="field.Required" :required="field.Required" />
							<label v-if="field.Header" :for="getUniqueId(field.Id)" class="form__label">{{ getFieldHeader(field) }}</label>
						</div>
					</template>
                </template>

                <!-- Custom Field (type: "Text Area") -->
                <template v-else-if="field.Type === 'textarea'">
                    <div class="_form_element _full_width form-row half">
                        <textarea :id="getUniqueId(field.Id)" :name="field.Id" class="form__textarea" data-custom-field :value="field.DefaultValue" placeholder=" " :title="getFieldTitle(field)" :aria-required="field.Required" :required="field.Required"></textarea>
						<label v-if="field.Header" :for="getUniqueId(field.Id)" class="form__label">{{ getFieldHeader(field) }}</label>
                    </div>
                </template>

                <!-- Custom Field (type: "Dropdown") -->
                <template v-else-if="field.Type === 'dropdown'">
                    <div v-if="field.Options" class="_form_element _full_width form-row half">
                        <label v-if="field.Header" :for="getUniqueId(field.Id)" class="form__label">{{ getFieldHeader(field) }}</label>
                        <div class="form__select-wrap">
                            <select :id="getUniqueId(field.Id)" :name="field.Id" class="form__select" data-custom-field :title="getFieldTitle(field)" :aria-required="field.Required" :required="field.Required">
                                <option value="" :selected="!field.Options.some(o => o.isdefault)">
                                    {{ getFieldHeader(field) }}
                                </option>
                                <option v-for="(option, dropdownIndex) in field.Options" :key="dropdownIndex" :value="option.Value" :selected="option.isdefault">
                                    {{ option.Label }}
                                </option>
                            </select>
                        </div>
                    </div>
                </template>

                <!-- Custom Field (type: "List Box") -->
                <template v-else-if="field.Type === 'listbox'">
                    <div v-if="field.Options" class="_form_element _full_width form-row half">
                        <label v-if="field.Header" :for="getUniqueId(field.Id)" class="form__label">{{ getFieldHeader(field) }}</label>
                        <div class="form__select-wrap form__select-wrap--multiple">
                            <select :id="getUniqueId(field.Id)" :name="field.Id" class="form__select" data-custom-field :title="getFieldTitle(field)" multiple :aria-required="field.Required" :required="field.Required">
                                <option v-for="(option, listboxIndex) in field.Options" :key="listboxIndex" :value="option.Value" :selected="option.isdefault">
                                    {{ option.Label }}
                                </option>
                            </select>
                        </div>
                    </div>
                </template>

                <!-- Custom Field (type: "Radio Button") -->
                <template v-else-if="field.Type === 'radio'">
                    <div class="_form_element _full_width form-row half">
                        <span v-if="field.Header" class="form__label">{{ getFieldHeader(field) }}</span>
                        <div class="form__checkbox-radio-list">
                            <div v-for="(option, radioIndex) in field.Options" :key="radioIndex" class="form__checkbox-radio-wrap">
                                <input :id="getUniqueId(field.Id, option.Id)" class="form__radio" data-custom-field type="radio" :name="field.Id" :value="option.Value" :checked="option.isdefault" :aria-required="field.Required" :required="field.Required" />
                                <label :for="getUniqueId(field.Id, option.Id)" class="form__checkbox-radio-label">{{ option.Label }}</label>
                            </div>
                        </div>
                    </div>
                </template>

                <!-- Custom Field (type: "Checkbox") -->
                <template v-else-if="field.Type === 'checkbox'">
                    <div v-if="field.Options" class="_form_element _full_width form-row narrow">
                        <template v-if="field.Options.length > 1">
                            <span v-if="field.Header" class="form__label">{{ getFieldHeader(field) }}</span>
                            <div class="form__checkbox-radio-list">
                                <div v-for="(option, checkboxIndex) in field.Options" :key="checkboxIndex" class="form__checkbox-radio-wrap">
                                    <input :id="getUniqueId(field.Id, option.Id)" class="form__checkbox" data-custom-field type="checkbox" :name="field.Id" :value="option.Value" :checked="option.isdefault" />
                                    <label :for="getUniqueId(field.Id, option.Id)" class="form__checkbox-radio-label">{{ option.Label }}</label>
                                </div>
                            </div>
                        </template>
                        <template v-else>
							<label class="checkbox">
								<input :id="getUniqueId(field.Id, field.Options[0].Id)" class="form__checkbox" data-custom-field type="checkbox" :name="field.Id" :value="field.Options[0].Value" :checked="field.Options[0].isdefault" :aria-required="field.Required" :required="field.Required" />
								<svg width="32" height="32" viewBox="-4 -4 39 39" aria-hidden="true" focusable="false"><rect class="checkbox-background" width="35" height="35" x="-2" y="-2" stroke="#6c6c6c" fill="white" stroke-width="2" rx="6" ry="6"></rect> <polyline class="checkbox-checkmark" points="4,14 12,23 28,5" stroke="transparent" stroke-width="4" stroke-dasharray="40" stroke-dashoffset="40" fill="none"></polyline></svg>
								<span v-html="getFieldHeader(field)"></span>
							</label>
                        </template>
                    </div>
                </template>

                <!-- Custom Field (type: "Policy") -->
                <template v-else-if="field.Type === 'policy'">
                    <div v-if="field.Options" class="_form_element _full_width form-row narrow">
                        <div class="form__checkbox-radio-wrap form__checkbox-radio-wrap--policy">
                            <label class="checkbox">
								<input :id="getUniqueId(field.Id, field.Options[0].Id)" class="form__checkbox" data-custom-field type="checkbox" :name="field.Id" :value="field.Options[0].Value" :checked="field.Options[0].isdefault" :aria-required="field.Required" :required="field.Required" />
								<svg width="32" height="32" viewBox="-4 -4 39 39" aria-hidden="true" focusable="false"><rect class="checkbox-background" width="35" height="35" x="-2" y="-2" stroke="#6c6c6c" fill="white" stroke-width="2" rx="6" ry="6"></rect> <polyline class="checkbox-checkmark" points="4,14 12,23 28,5" stroke="transparent" stroke-width="4" stroke-dasharray="40" stroke-dashoffset="40" fill="none"></polyline></svg>
								<span v-html="getPolicyLabel(field)"></span>
							</label>
                        </div>
                    </div>
                </template>

                <!-- Custom Field (type: "Hidden") -->
                <template v-else-if="field.Type === 'hidden'">
                    <input :id="getUniqueId(field.Id)" :name="field.Id" data-custom-field type="hidden" :value="field.DefaultValue" />
                </template>

            </template>
            <div v-if="form.CFields" class="_form_element _full_width form-row half">
                <div class="form__btn-wrap">
                    <button type="submit" class="form__btn btn">
                        <span class="btn__icon">
                            <svg-inline name="arrow" />
                        </span>
                        <span class="btn__text">{{ form.Button }}</span>
                        <span class="btn__icon">
                            <svg-inline name="arrow" />
                        </span>
                    </button>
                </div>
            </div>
            <input type="hidden" name="areaId" :value="areaId" />
            <div class="spinner-wrap" :class="{ 'spinner-wrap--absolute': isSubmitting }" :aria-hidden="isSubmitting ? 'false' : 'true'">
                <div class="spinner"></div>
            </div>
        </template>
    </form>
    <div v-if="message" class="form__message" :class="{ 'form__message--error': hasError }">
        {{ message }}
    </div>
</template>
<script>
import apiService from '../../api';
import { getCurrentInstance } from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3';

export default {
    name: 'AcForm',
    props: {
        formId: {
            type: String,
            default: ''
        },
        formKey: {
            type: String,
            default: ''
        },
		privacyPolicyLink: {
			type: String,
			default: ''
		},
		privacyPolicyText: {
			type: String,
			default: ''
		}
    },
    data() {
        return {
            areaId: document.documentElement.dataset.areaId,
            isInitializing: true,
            isSubmitting: false,
            hasBeenSubmitted: false,
            form: [],
			formElement: null,
            hasError: false,
			userBlocked: false,
			submitAttempts: 0,
            message: ''
        };
    },
    computed: {
        filteredCFields() {
            if (!this.isInitializing) {
                const allowedTypes = ['header', 'fullname', 'firstname', 'lastname', 'customer_account', 'email', 'phone', 'text', 'textarea', 'dropdown', 'listbox', 'radio', 'checkbox', 'policy', 'hidden'];
                let filteredObject = this.form.CFields;
                if (this.form.CFields) {
                    filteredObject = this.form.CFields.filter(cfield => (allowedTypes.includes(cfield.Type)));
                }
                return filteredObject;
            }
            return null;
        }
    },
    created() {
        this.getForm();
    },
	mounted() {
		const app = getCurrentInstance();
		app.appContext.app.use(VueReCaptcha, { siteKey: '6Lcbgc8pAAAAAC85E8BXFnPIW56e9TaD5hxqc-o4' });
	},
    methods: {
        getForm() {
            const vm = this;

            apiService.ac.getForm(vm.formId, document.documentElement.dataset.areaId)
                .then(response => {
                    vm.form = response.data.Data;
                })
                .catch(errors => {
                    const errorResponse = errors.response.data.message ? errors.response.data.message : `Error (status code ${errors.response.status})`;
                    vm.hasError = true;
                    vm.message = errorResponse;
                })
                .then(function () {
                    vm.isInitializing = false;
                });
        },
        submitForm(contactObj) {
            const vm = this;

			vm.isSubmitting = true;
            vm.hasError = false;
            vm.message = '';

            apiService.ac.addContact(contactObj, vm.form.ActionData, document.documentElement.dataset.areaId)
                .then(function () {
                    // Tracking
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'form_submission',
                        'formName': vm.form.Name,
                        'userEmail': vm.$refs.email[0].value
                    });

                    if (vm.form.Submit === 'open-url' && vm.form.SubmitData.Url) {
                        window.location.href = vm.form.SubmitData.Url;
                    } else {
                        vm.message = vm.form.Thanks;
                        vm.hasBeenSubmitted = true;
                    }
                })
                .catch(errors => {
                    const errorResponse = errors;
                    vm.hasError = true;
                    vm.message = errorResponse;
                    vm.hasBeenSubmitted = true;
                })
                .then(function () {
                    vm.isSubmitting = false;
                });
        },
        mappedContactObj() {
            const form = this.$refs.form,
                formElements = form.querySelectorAll('input, textarea, select'),
                areaIdField = form.querySelector('input[type="hidden"][name="areaId"]'),
                formData = {
                    fieldValues: []
                };

            if (formElements) {
                formElements.forEach(el => {
                    if (el.hasAttribute('data-custom-field')) {
                        if (el.type && el.type === 'radio') {
                            if (el.checked) {
                                formData.fieldValues.push({ field: el.name, value: el.value });
                            }

                        } else if (el.type && el.type === 'checkbox') {
                            if (el.checked && !formData.fieldValues.some(e => e.field === el.name)) {
                                const possibleSiblings = form.querySelectorAll(`[name="${el.name}"]:checked`);
                                const selectedCheckboxes = Array.from(possibleSiblings).map(({ value }) => value);
                                const values = selectedCheckboxes.join('||');
                                formData.fieldValues.push({ field: el.name, value: `||${values}||` });
                            }
                        } else if (el.tagName === 'SELECT' && el.hasAttribute('multiple')) {
                            if (el.selectedIndex !== -1) {
                                const selectedOptions = Array.from(el.selectedOptions).map(({ value }) => value);
                                const values = selectedOptions.join('||');
                                formData.fieldValues.push({ field: el.name, value: `||${values}||` });
                            }
                        } else {
                            if (el.value) {
                                formData.fieldValues.push({ field: el.name, value: el.value });
                            }
                        }
                    } else {
                        if (!el.value) {
                            return;
                        }

                        if (el.name === 'fullname') {
                            const fullName = el.value;
                            let firstName = fullName;
                            let lastName = '';

                            if (fullName.indexOf(' ') >= 0) {
                                firstName = fullName.substr(0,fullName.indexOf(' '));
                                lastName = fullName.substr(fullName.indexOf(' ') + 1);
                            }

                            formData['firstname'] = firstName;
                            formData['lastname'] = lastName;
                        } else {
                            formData[el.name] = el.value;
                        }
                    }
                });
            }

            if (areaIdField) {
                if (areaIdField.value) {
                    formData.fieldValues.push({ field: areaIdField.name, value: areaIdField.value });
                }
            }

            return formData;
        },
        getUniqueId(value1, value2 = '') {
            let uniqueId = `form[${this.formKey}]-${value1}`;
            uniqueId = value2 ? `${uniqueId}-${value2}` : uniqueId;

            return uniqueId;
        },
        getFieldHeader(field) {
            let label = field.Header;

            if (field.Required && label)
            {
                label = `${label.replace(/\*/g, '')}*`;
            }

            return label;
        },
        getFieldTitle(field, isPlaceholder) {
            let label = field.Default_text;

            if (field.Required && label)
            {
                label = label.replace(/\*/g, '');

                if (isPlaceholder && !field.Header) {
                    label = `${label}*`;
                }
            }

            return label;
        },
        getPolicyLabel(field) {
            let label = field.Options[0].Label;

            if (field.Required && label)
            {
                label = label.replace(/\*/g, '');
				label = label.replace(/href/g, `href="${this.privacyPolicyLink}"`);

                if (!field.Header) {
                    label = `${label}*`;
                }
            }

            return label;
        },
		async recaptcha(e) {
			const contactObj = this.mappedContactObj();

			if (this.$refs.form.reportValidity() && !this.userBlocked) {
				this.isSubmitting = true;

				await this.$recaptchaLoaded();

				const token = await this.$recaptcha('signup');
				const event = {
					"event": {
						"token": token,
						"expectedAction": "signup",
						"siteKey": "6Lcbgc8pAAAAAC85E8BXFnPIW56e9TaD5hxqc-o4"
					}
				};

				fetch('https://recaptchaenterprise.googleapis.com/v1/projects/green-instrument-1714744443843/assessments?key=AIzaSyBAalIGSoXSaqT9_4uW4o7QQiTB1OyGa0w', {
					method: 'post',
					body: JSON.stringify(event),
				})
				.then(response => response.json())
				.then(data => {
					if (data.riskAnalysis) {
						if (data.riskAnalysis.score) {
							this.hasError = false;

							if (data.riskAnalysis.score >= 0.7) {
								this.submitForm(contactObj);
							} else {
								if (this.userAttemps >= 3) {
									this.isSubmitting = false;
									this.userBlocked = true;
								} else {
									this.isSubmitting = false;
									this.userAttemps = this.userAttemps + 1;
								}
							}
						} else {
							this.isSubmitting = false;
							this.hasError = true;
						}
					} else {
						this.isSubmitting = false;
						this.hasError = true;
					}
				})
				.catch(error => {
					this.isSubmitting = false;
					this.hasError = true;
				});
			}
		}
    }
};
</script>
