export function setupBaseScript() {
    (() => {
        // 'use strict';

        if (window.NodeList && !NodeList.prototype.forEach) {
            NodeList.prototype.forEach = Array.prototype.forEach;
        }

        /**
         * Prevent function (ex. scroll) from being executed on every call
         * @param  {Function}  callback  Callback
         * @param  {String}    delay     Delay
         * @return {Function}            Callback
         */
        const debounce = (callback, delay) => {
            let timer = null;

            return function () {
                const context = this;
                const args = arguments;

                clearTimeout(timer);

                timer = setTimeout(() => {
                    callback.apply(context, args);
                }, delay);
            };
        };

        /*
        * Get the value of a query string from a URL
        * @@param  {String}  field  The field to get the value of
        * @@param  {String}  url    The URL to get the value from [optional]
        * @@return {String}         The value
        */
        const getQueryString = (field, url) => {
            let href = url;

            if (!url) {
                href = window.location.search;
            }

            const reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
            const string = reg.exec(href);

            return string ? string[1] : null;
        };

        const getEventPrefix = (type) => {
            const element = document.createElement('div');
            const prefixes = ['', 'o', 'ms', 'moz', 'webkit'];
            let eventName = '';

            prefixes.forEach((prefix) => {
                if (element.style[prefix + type] !== undefined) {
                    eventName = prefix + type + 'end';
                }
            });

            return eventName;
        };

        // Add class to detect if device is handheld
        // DON'T use touch as it will give false positives (laptop with touch)
        if (window.orientation !== undefined) {
            document.documentElement.classList.add('deviceorientation');
        } else {
            document.documentElement.classList.add('no-deviceorientation');
        }

        let connection = '2g';
        const lowfi = ['slow-2g', '2g', '3g'];

        if (navigator.connection && navigator.connection.effectiveType && navigator.connection.effectiveType.length > 0) {
            if (lowfi.indexOf(navigator.connection.effectiveType) >= 0) {
                connection = 'slow';
            } else {
                connection = 'fast';
            }
        } else {
            // Hotfix: allow faster mode for some tablet devices that
            // don't support navigator.connection, but still support falling
            // back to slow mode for the majority.
            if (window.screen.height / window.screen.width === 1366 / 1024) {
                connection = 'fast';
            } else if (window.screen.height / window.screen.width === 1112 / 834) {
                connection = 'fast';
            } else {
                connection = 'slow';
            }
        }
        document.documentElement.classList.add('connection-' + connection);

        // Add class if `display: grid` isn't supported
        if (!window.CSS) {
            document.documentElement.classList.add('no-cssgrid');
        }


        /**
         * Detect if user prefers reduced motion or not.
         */
        const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
        let prefersReducedMotion = mediaQuery.matches;
        mediaQuery.addEventListener('change', () => {
            prefersReducedMotion = mediaQuery.matches;

            if (!prefersReducedMotion) {
                //
            }
        });


        // Used keycodes
        const KEYCODE = {
            SPACE: 32,
            ESC: 27,
            HOME: 36,
            END: 35,
            ARROW_RIGHT: 39,
            ARROW_LEFT: 37
        };

        // INITIATE
        document.onreadystatechange = () => {
            if (document.readyState === 'complete') {
                if (document.querySelector('.navigation-overlay')) {
                    document.querySelector('.navigation-overlay').classList.remove('hidden');
                    navigationHeight = document.querySelector('.navigation-overlay .site-navigation').offsetHeight;
                }

                if (document.querySelector('.site-header')) {
                    document.documentElement.style.setProperty('--headerHeight', document.querySelector('.site-header').offsetHeight + 'px');
                }
            }
        };

        window.addEventListener('resize', debounce(() => {
            if (document.querySelector('.site-header')) {
                document.documentElement.style.setProperty('--headerHeight', document.querySelector('.site-header').offsetHeight + 'px');
            }
        }), 250);

        let bodyRect = document.body.getBoundingClientRect();
        let list = document.querySelector('.draggie .case-latest');
        let listRect = '';
        if (list) {
            listRect = list.getBoundingClientRect();
        }

        // Scroll reveal
        let revealElements = document.querySelectorAll('[data-action="into-view"]');
        let observer;

        const scrollReveal = (event) => {
            const element = (event.target || event);
            const ratio = Math.abs(Math.abs(element.getBoundingClientRect().top / element.getBoundingClientRect().height * 100) - 100) / 100;

            if (ratio > 0.25) {
                element.removeAttribute('data-action');
                revealElements = document.querySelectorAll('[data-action="into-view"]');
                element.classList.add('js-in-view');

                setTimeout(() => {
                    bodyRect = document.body.getBoundingClientRect();
                    if (list) {
                        list = document.querySelector('.draggie .case-latest');
                        listRect = list.getBoundingClientRect();
                    }
                }, 500);
            }
        };

        const observerReveal = (event) => {
            const element = event.target;
            const ratio = event.intersectionRatio;

            if (ratio > 0.25) {
                observer.unobserve(element);
                element.classList.add('js-in-view');

                setTimeout(() => {
                    bodyRect = document.body.getBoundingClientRect();
                    if (list) {
                        list = document.querySelector('.draggie .case-latest');
                        listRect = list.getBoundingClientRect();
                    }
                }, 500);
            }
        };

        const onIntersection = (revealElements) => {
            revealElements.forEach((element) => {
                observerReveal(element);
            });
        };

        if (!('IntersectionObserver' in window)) {
            let ticking = false;

            window.addEventListener('scroll', () => {
                if (!ticking) {
                    requestAnimationFrame(() => {
                        ticking = false;
                        revealElements.forEach((element) => {
                            if (element.getBoundingClientRect().top < window.innerHeight && element.getBoundingClientRect().top + element.getBoundingClientRect().height > 0.25) {
                                scrollReveal(element);
                            }
                        });
                    });
                }
                ticking = true;
            }, false);
        } else {
            observer = new IntersectionObserver(onIntersection, {
                rootMargin: '0px 0px',
                threshold: [0.25]
            });

            revealElements.forEach((element) => {
                observer.observe(element);
            });
        }

        // Scroll parrallax
        let pageScroll = document.body.scrollTop || document.documentElement.scrollTop;

        document.addEventListener('scroll', (event) => {
            pageScroll = document.body.scrollTop || document.documentElement.scrollTop;
            if (pageScroll > 100) {
                document.querySelector('.site-header').classList.add('js-shrink');
                document.documentElement.style.setProperty('--headerHeight', document.querySelector('.site-header').offsetHeight + 'px');
            } else {
                document.querySelector('.site-header').classList.remove('js-shrink');
                document.documentElement.style.setProperty('--headerHeight', document.querySelector('.site-header').offsetHeight + 'px');
            }
        }, {
            passive: true
        });

        const parrallaxElements = document.querySelectorAll('[data-action="scroll-view"]');

        const imageParrallax = () => {
            const headerHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--headerHeight'));
            parrallaxElements.forEach((element) => {
                const image = element.querySelector('.image');

                if (image) {
                    if (pageScroll + window.innerHeight < image.offsetTop) {
                        // before image is in view
                        image.style.backgroundPosition = '50% 100%';
                    } else if (pageScroll + window.innerHeight >= image.offsetTop && pageScroll <= image.offsetTop + image.offsetHeight - headerHeight) {
                        // image is in view
                        const scrollDistancepercent = ((image.offsetTop + image.offsetHeight) - pageScroll) / ((image.offsetTop + image.offsetHeight) - (image.offsetTop - window.innerHeight));

                        image.style.backgroundPosition = '50% ' + (scrollDistancepercent * 100) + '%';
                    } else if (pageScroll > image.offsetTop + image.offsetHeight - headerHeight) {
                        // after image is in view
                        image.style.backgroundPosition = '50% 0%';
                    }
                }
            });
            window.requestAnimationFrame(imageParrallax);
        };
        window.requestAnimationFrame(imageParrallax);

        // Embeds
        const embedRatio = (element) => {
            const embed = element.querySelector('iframe, object, embed');
            let ratio = '';

            if (embed) {
                if (embed.getAttribute('width') > embed.getAttribute('height')) {
                    ratio = (embed.getAttribute('height') / embed.getAttribute('width')) * 100;
                } else {
                    ratio = (embed.getAttribute('width') / embed.getAttribute('height')) * 100;
                }

                element.style.paddingBottom = ratio + '%';
            }
        };
        const embeds = document.querySelectorAll('.embed-responsive');
        embeds.forEach((embed) => {
            embedRatio(embed);
        });

        // Form fields
        const fieldValidate = (event) => {
            const element = event.target;

            if (element.value !== '') {
                element.classList.add('js-not-empty');
            } else {
                element.classList.remove('js-not-empty');
            }
        };
        const formFields = document.querySelectorAll('input, textarea');
        formFields.forEach((field) => {
            if (field.value !== '') {
                field.classList.add('js-not-empty');
            } else {
                field.classList.remove('js-not-empty');
            }

            field.addEventListener('change', fieldValidate);
        });

        const formSubmit = (event) => {
            const element = event.target;
            element.classList.add('js-submitting');

            const formButtons = element.querySelectorAll('button[type="submit"]');
            formButtons.forEach((button) => {
                button.disabled = true;
            });

            setTimeout(() => {
                formButtons.forEach((button) => {
                    button.removeAttribute('disabled');
                });
            }, 4000);
        };
        const forms = document.querySelectorAll('.form form');
        forms.forEach((form) => {
            form.addEventListener('submit', formSubmit);
        });

        // Navigation
        let navigationHeight = 0;

        const navigationOpen = (event) => {
            const element = event.target;

            document.body.classList.add('js-navigation-open');
            element.setAttribute('aria-expanded', true);
        };
        const navigationButtonOpen = document.querySelectorAll('[data-action*="navigation-open"]');
        navigationButtonOpen.forEach((button) => {
            button.addEventListener('click', navigationOpen);
        });

        const navigationClose = (event) => {
            document.body.classList.remove('js-navigation-open');
            navigationButtonOpen.forEach((button) => {
                button.setAttribute('aria-expanded', false);
            });
            navigationButtonToggle.forEach((button) => {
                button.setAttribute('aria-expanded', false);
            });
        };
        const navigationButtonClose = document.querySelectorAll('[data-action*="navigation-close"]');
        navigationButtonClose.forEach((button) => {
            button.addEventListener('click', navigationClose);
        });

        const navigationToggle = (event) => {
            const element = event.target;

            document.body.classList.toggle('js-navigation-open');
            element.setAttribute('aria-expanded', !(element.getAttribute('aria-expanded') === true));
        };
        const navigationButtonToggle = document.querySelectorAll('[data-action*="navigation-toggle"]');
        navigationButtonToggle.forEach((button) => {
            button.addEventListener('click', navigationToggle);
        });


        const navigationSubitem = (event) => {
            event.preventDefault();

            const element = event.target;

            document.body.classList.add('js-navigation-level-2');

            const links = element.closest('.level-1').querySelectorAll('.has-children');
            links.forEach((link) => {
                if (link === element.closest('.has-children')) {
                    link.classList.add('js-active');
                } else {
                    link.classList.remove('js-active');
                }
            });

            const children = element.closest('.level-1').querySelectorAll('.level-2');
            children.forEach((child) => {
                if (child === element.closest('.has-children').querySelector('.level-2')) {
                    child.classList.add('js-active');
                } else {
                    child.classList.remove('js-active');
                }
            });

            navigationHeight = Math.max(element.closest('.level-1').offsetHeight, element.parentNode.querySelector('.level-2').offsetHeight);
            document.querySelector('.navigation-overlay .site-navigation').style.height = navigationHeight + 'px';
        };
        const navigationLinkSubitem = document.querySelectorAll('.navigation-overlay .site-navigation .has-children > a');
        navigationLinkSubitem.forEach((link) => {
            link.addEventListener('click', navigationSubitem);
        });

        const navigationBack = (event) => {
            event.preventDefault();

            const element = event.target;

            document.body.classList.remove('js-navigation-level-2');

            const links = element.closest('.level-1').querySelectorAll('.has-children');
            links.forEach((link) => {
                link.classList.remove('js-active');
            });

            const children = element.closest('.level-1').querySelectorAll('.level-2');
            children.forEach((child) => {
                child.classList.remove('js-active');
            });
        };
        const navigationLinkBack = document.querySelectorAll('.navigation-overlay .site-navigation .back');
        navigationLinkBack.forEach((button) => {
            button.addEventListener('click', navigationBack);
        });

        if (document.querySelector('.navigation-overlay .site-navigation')) {
            document.querySelector('.navigation-overlay .site-navigation').addEventListener(getEventPrefix('transition'), () => {
                if (!document.body.classList.contains('js-navigation-level-2')) {
                    document.querySelector('.navigation-overlay .site-navigation').removeAttribute('style');
                }
            });
        }

        window.addEventListener('keydown', (event) => {
            if (event.keyCode === KEYCODE.ESC) {
                // Close navigation
                document.body.classList.remove('js-navigation-open');
                navigationButtonOpen.forEach((button) => {
                    button.setAttribute('aria-expanded', false);
                });
                navigationButtonToggle.forEach((button) => {
                    button.setAttribute('aria-expanded', false);
                });
            }
        });

        // Search
        const searchOpen = (event) => {
            const element = event.target;

            document.body.classList.add('js-search-open');
            element.setAttribute('aria-expanded', true);
        };
        const searchButtonOpen = document.querySelectorAll('[data-action*="search-open"]');
        searchButtonOpen.forEach((button) => {
            button.addEventListener('click', searchOpen);
        });

        const searchClose = (event) => {
            document.body.classList.remove('js-search-open');
            searchButtonOpen.forEach((button) => {
                button.setAttribute('aria-expanded', false);
            });
        };
        const searchButtonClose = document.querySelectorAll('[data-action*="search-close"]');
        searchButtonClose.forEach((button) => {
            button.addEventListener('click', searchClose);
        });

        const searchToggle = (event) => {
            document.body.classList.toggle('js-search-open');
        };
        const searchButtonToggle = document.querySelectorAll('[data-action*="search-toggle"]');
        searchButtonToggle.forEach((button) => {
            button.addEventListener('click', searchToggle);
        });

        const searchSubmit = (event) => {
            const element = event.target;

            if (element.querySelector('input[type="search"]') && element.querySelector('input[type="search"]').value === '') {
                event.preventDefault();
                searchToggle();
            }
        };
        const searchForms = document.querySelectorAll('.site-search form');
        searchForms.forEach((form) => {
            form.addEventListener('submit', searchSubmit);
        });

        // Header
        const frontpageHeaderItems = document.querySelectorAll('.frontpage-header .item');
        const frontpageHeaderResize = () => {
            if (window.matchMedia('(min-width: 960px)').matches) {
                frontpageHeaderItems.forEach((item) => {
                    if (item.querySelector('.text-outer') && item.querySelector('.heading')) {
                        const textHeight = item.querySelector('.text-outer').offsetHeight;

                        item.querySelector('.heading').style.transform = 'translateY(' + textHeight + 'px)';
                    }
                });
            } else {
                frontpageHeaderItems.forEach((item) => {
                    if (item.querySelector('.text-outer') && item.querySelector('.heading')) {
                        item.querySelector('.heading').style.transform = null;
                    }
                });
            }
        };

        frontpageHeaderResize();
        window.addEventListener('resize', debounce(frontpageHeaderResize, 100));

        // Cases
        const caseLatestItems = document.querySelectorAll('.case-latest .case');
        const caseLatestResize = () => {
            if (window.matchMedia('(min-width: 960px)').matches) {
                caseLatestItems.forEach((item) => {
                    if (item.querySelector('.date') && item.querySelector('.link') && item.querySelector('.heading')) {
                        const linkHeight = item.querySelector('.link').offsetHeight;

                        item.querySelector('.date').style.transform = 'translateY(calc(' + linkHeight + 'px + 2.4rem))';
                        item.querySelector('.heading').style.transform = 'translateY(calc(' + linkHeight + 'px + 2.4rem))';
                    }
                });
            } else {
                caseLatestItems.forEach((item) => {
                    if (item.querySelector('.date') && item.querySelector('.link') && item.querySelector('.heading')) {
                        item.querySelector('.date').style.transform = null;
                        item.querySelector('.heading').style.transform = null;
                    }
                });
            }

            bodyRect = document.body.getBoundingClientRect();
            listRect = list.getBoundingClientRect();
        };

        if (caseLatestItems.length > 0) {
            caseLatestResize();
            window.addEventListener('resize', debounce(caseLatestResize, 100));
            window.addEventListener('scroll', debounce(() => {
                bodyRect = document.body.getBoundingClientRect();
                listRect = list.getBoundingClientRect();
            }), 100);
        }

        let cursorDown;
        let cursorXPos;
        let moved = 0;
        let dragged = false;
        const root = document.documentElement;
        //const cursor = document.querySelector('.draggie .cursor');
        const indicator = document.querySelector('.draggie .indicator');
        const previous = document.querySelector('.draggie .controls .previous');
        const next = document.querySelector('.draggie .controls .next');
        let maxWidth = '';
        if (list) {
            maxWidth = list.offsetWidth - list.scrollWidth;
        }

        const dragStart = (event) => {
            let elementEvent = event;
            if (event.changedTouches !== undefined) {
                elementEvent = event.changedTouches[0];
            }

            cursorDown = true;
            cursorXPos = elementEvent.pageX;
            maxWidth = list.offsetWidth - list.scrollWidth;
        };
        const dragMove = (event) => {
            if (cursorDown) {
                let elementEvent = event;
                if (event.changedTouches !== undefined) {
                    elementEvent = event.changedTouches[0];
                }

                if (elementEvent.pageY > 0) {
                    event.preventDefault();
                }
                if (moved + (elementEvent.pageX - cursorXPos) > 0) {
                    moved = 0;
                    list.style.transform = 'translateX(' + moved + 'px)';
                    indicator.style.setProperty('--progress', (moved / maxWidth));

                    if (previous && next) {
                        previous.disabled = true;
                        next.disabled = false;
                    }
                } else if (moved + (elementEvent.pageX - cursorXPos) < maxWidth) {
                    moved = maxWidth;
                    list.style.transform = 'translateX(' + moved + 'px)';
                    indicator.style.setProperty('--progress', (moved / maxWidth));

                    if (previous && next) {
                        previous.disabled = false;
                        next.disabled = true;
                    }
                } else {
                    list.style.transform = 'translateX(' + (moved + (elementEvent.pageX - cursorXPos)) + 'px)';
                    indicator.style.setProperty('--progress', ((moved + (elementEvent.pageX - cursorXPos)) / maxWidth));

                    if (previous && next) {
                        previous.disabled = false;
                        next.disabled = false;
                    }
                }

                if (event.target.closest('.case')) {
                    event.target.closest('.case').querySelector('a').style.pointerEvents = 'none';
                }

                dragged = true;
            }
        };
        const dragEnd = (event) => {
            let elementEvent = event;
            if (event.changedTouches !== undefined) {
                elementEvent = event.changedTouches[0];
            }

            cursorDown = false;
            moved = parseInt(list.style.transform.replace('translateX(', '').replace('px)', ''));

            if (event.target.closest('.case')) {
                event.target.closest('.case').querySelector('a').style.pointerEvents = null;
            }

            listRect = list.getBoundingClientRect();
        };

        if (list) {
            list.addEventListener('mousedown', dragStart, {
                passive: true
            });
            list.addEventListener('touchstart', dragStart, {
                passive: true
            });
            list.addEventListener('mousemove', dragMove);
            list.addEventListener('touchmove', dragMove);
            list.addEventListener('mouseup', dragEnd, {
                passive: true
            });
            list.addEventListener('touchend', dragEnd, {
                passive: true
            });

            if (previous && next) {
                previous.addEventListener('click', (event) => {
                    if (moved < 0) {
                        const theChoosenOne = Array.from(list.children).reverse().find((element) => {
                            return -Math.abs(element.offsetLeft) > moved;
                        });

                        moved = -Math.abs(theChoosenOne.offsetLeft);
                        if (moved >= 0) {
                            moved = 0;

                            previous.disabled = true;
                            next.disabled = false;
                        } else if (moved <= maxWidth) {
                            moved = maxWidth;

                            previous.disabled = false;
                            next.disabled = true;
                        } else {
                            previous.disabled = false;
                            next.disabled = false;
                        }

                        list.style.transform = 'translateX(' + moved + 'px)';
                        indicator.style.setProperty('--progress', (moved / maxWidth));
                    }
                });
                next.addEventListener('click', (event) => {
                    if (moved > -Math.abs(maxWidth)) {
                        const theChoosenOne = Array.from(list.children).find((element) => {
                            return -Math.abs(element.offsetLeft) < moved;
                        });

                        moved = -Math.abs(theChoosenOne.offsetLeft);
                        if (moved >= 0) {
                            moved = 0;

                            previous.disabled = true;
                            next.disabled = false;
                        } else if (moved <= maxWidth) {
                            moved = maxWidth;

                            previous.disabled = false;
                            next.disabled = true;
                        } else {
                            previous.disabled = false;
                            next.disabled = false;
                        }

                        list.style.transform = 'translateX(' + moved + 'px)';
                        indicator.style.setProperty('--progress', (moved / maxWidth));
                    }
                });
            }

            document.addEventListener('mousemove', (event) => {
                const x = event.clientX / innerWidth;
                const y = event.clientY / innerHeight;

                root.style.setProperty('--mouse-x', x);
                root.style.setProperty('--mouse-y', y);

                if (event.clientY >= listRect.top && event.clientX <= Math.min(listRect.left + list.scrollWidth, bodyRect.right) && event.clientY <= listRect.bottom && event.clientX >= Math.max((parseInt(list.style.transform.replace('translateX(', '').replace('px)', '')) || 0) + listRect.left, bodyRect.left)) {
                    document.body.classList.add('js-cursor-show');
                } else {
                    document.body.classList.remove('js-cursor-show');
                }
            });
        }

        // Tabs
        const tabChange = (element) => {
            if (element) {
                const tabs = element.closest('.tabs');
                const tabTarget = document.getElementById(element.getAttribute('aria-controls'));

                // Buttons
                tabs.querySelectorAll('.tab-button').forEach((button) => {
                    if (button === element) {
                        button.classList.add('js-active');
                        button.setAttribute('aria-selected', true);
                        button.removeAttribute('tabindex');
                        button.focus();
                    } else {
                        button.classList.remove('js-active');
                        button.setAttribute('aria-selected', false);
                        button.setAttribute('tabindex', -1);
                    }
                });

                // Content
                tabs.querySelectorAll('.tab-content').forEach((content) => {
                    if (content === tabTarget) {
                        content.classList.add('js-active');
                        content.setAttribute('aria-hidden', false);
                    } else {
                        content.classList.remove('js-active');
                        content.setAttribute('aria-hidden', true);
                    }
                });
            }
        };
        const tabOpen = (event) => {
            if (!(event.ctrlKey || event.metaKey || event.shiftKey)) {
                event.preventDefault();
            }
            const element = event.target;

            tabChange(element);
        };
        const tabButtonOpen = document.querySelectorAll('[data-action="tab-open"]');
        tabButtonOpen.forEach((button) => {
            button.addEventListener('click', tabOpen);
        });

        if (window.location.hash.startsWith('tab', 1) && document.getElementById(window.location.hash.replace('#', ''))) {
            tabChange(document.getElementById(document.getElementById(window.location.hash.replace('#', '')).getAttribute('aria-labelledby')));
        }
        // Scroll to forms "Thank you" message and change tab if needed
        if (getQueryString('pid') && getQueryString('s')) {
            let target = document.getElementById(getQueryString('pid'));

            if (document.getElementById('tab-' + getQueryString('pid'))) {
                target = document.getElementById('tab-' + getQueryString('pid'));

                tabChange(document.getElementById(target.getAttribute('aria-labelledby')));
            }

            const headerHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--headerHeight'));
            const offsetTop = target.offsetTop - headerHeight;
            window.addEventListener('load', function () {
                scroll({
                    top: offsetTop,
                    behavior: 'smooth'
                });
            });
        }

        window.addEventListener('hashchange', () => {
            if (window.location.hash.startsWith('tab', 1) && document.getElementById(window.location.hash.replace('#', ''))) {
                tabChange(document.getElementById(document.getElementById(window.location.hash.replace('#', '')).getAttribute('aria-labelledby')));
            }
        }, false);

        window.addEventListener('keydown', (event) => {
            const focusedElement = document.activeElement;
            const tabs = document.activeElement.closest('.tabs');

            if (focusedElement.getAttribute('data-action') === 'tab-open') {
                if (event.keyCode === KEYCODE.SPACE) {
                    // Toggle tab
                    if (!(event.ctrlKey || event.metaKey || event.shiftKey)) {
                        event.preventDefault();
                    }
                    tabChange(focusedElement);
                }
            }
            if (focusedElement && tabs) {
                if (event.keyCode === KEYCODE.ARROW_LEFT) {
                    // Open prevoius tab
                    if (focusedElement.closest('li').previousElementSibling && focusedElement.closest('li').previousElementSibling.querySelector('.tab-button')) {
                        tabChange(focusedElement.closest('li').previousElementSibling.querySelector('.tab-button'));
                    }
                }
                if (event.keyCode === KEYCODE.ARROW_RIGHT) {
                    // Open next tab
                    if (focusedElement.closest('li').nextElementSibling && focusedElement.closest('li').nextElementSibling.querySelector('.tab-button')) {
                        tabChange(focusedElement.closest('li').nextElementSibling.querySelector('.tab-button'));
                    }
                }
                if (event.keyCode === KEYCODE.HOME) {
                    // Open first tab
                    if (tabs.querySelector('li:first-child .tab-button')) {
                        event.preventDefault();
                        tabChange(tabs.querySelector('li:first-child .tab-button'));
                    }
                }
                if (event.keyCode === KEYCODE.END) {
                    // Open last tab
                    if (tabs.querySelector('li:last-child .tab-button')) {
                        event.preventDefault();
                        tabChange(tabs.querySelector('li:last-child .tab-button'));
                    }
                }
            }
        });

        // Active campaign forms
        (function () {
            let acAppSetup = {
                areaId: document.documentElement.dataset.areaId,
                isInitializing: true,
                isSubmitting: false,
                hasBeenSubmitted: false,
                form: [],
                hasError: false,
                message: ''
            }

            const activeCampaignForms = document.querySelectorAll('[data-ac-form-id]');

            activeCampaignForms.forEach(form => {
                const formId = form.getAttribute('data-ac-form-id');
                const areaId = acAppSetup.areaId;

                if (formId !== undefined) {
                    fetch(`/api/activecampaign/getform?formId=${formId}&areaId=${areaId}`, {
                        method: 'get'
                    }).then(response => response.json()).then(data => {
                        acAppSetup.form = data.Form;
                    }).catch(errors => {
                        const errorResponse = errors.response.data.message ? errors.response.data.message : `Error (status code ${errors.response.status})`;
                        acAppSetup.hasError = true;
                        acAppSetup.message = errorResponse;
                    }).then(function () {
                        acAppSetup.isInitializing = false;
                    });
                }
            });

            function submitForm() {
                const contactObj = getMappedContactObj();

                const postObect = {
                    contactRequest: {
                        contact: contactObj,
                        actionData: acAppSetup.form.ActionData
                    }
                }

                // Add contact
                fetch('/api/activecampaign/addcontact', {
                    method: 'post',
                    body: JSON.stringify(postObect),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
            }

            function getMappedContactObj() {
                const form = acAppSetup.form;
                const formElements = form.querySelectorAll('input, textarea, select');
                const areaIdField = form.querySelector('input[type="hidden"][name="areaId"]');
                const formData = {
                    fieldValues: []
                };

                acAppSetup.isSubmitting = true;
                acAppSetup.hasError = false;
                acAppSetup.message = '';

                if (formElements) {
                    formElements.forEach(el => {
                        if (el.hasAttribute('data-custom-field')) {
                            if (el.Type && el.Type === 'radio') {
                                if (el.checked) {
                                    formData.fieldValues.push({ field: el.name, value: el.value });
                                }

                            } else if (el.Type && el.Type === 'checkbox') {
                                if (el.checked && !formData.fieldValues.some(e => e.field === el.name)) {
                                    const possibleSiblings = form.querySelectorAll(`[name="${el.name}"]:checked`);
                                    const selectedCheckboxes = Array.from(possibleSiblings).map(({ value }) => value);
                                    const values = selectedCheckboxes.join('||');
                                    formData.fieldValues.push({ field: el.name, value: `||${values}||` });
                                }
                            } else if (el.tagName === 'SELECT' && el.hasAttribute('multiple')) {
                                if (el.selectedIndex !== -1) {
                                    const selectedOptions = Array.from(el.selectedOptions).map(({ value }) => value);
                                    const values = selectedOptions.join('||');
                                    formData.fieldValues.push({ field: el.name, value: `||${values}||` });
                                }
                            } else {
                                if (el.value) {
                                    formData.fieldValues.push({ field: el.name, value: el.value });
                                }
                            }
                        } else {
                            if (!el.value) {
                                return;
                            }

                            if (el.name === 'fullname') {
                                const fullName = el.value;
                                let firstName = fullName;
                                let lastName = '';

                                if (fullName.indexOf(' ') >= 0) {
                                    firstName = fullName.substr(0,fullName.indexOf(' '));
                                    lastName = fullName.substr(fullName.indexOf(' ') + 1);
                                }

                                formData['firstname'] = firstName;
                                formData['lastname'] = lastName;
                            } else {
                                formData[el.name] = el.value;
                            }
                        }
                    });
                }

                if (areaIdField) {
                    if (areaIdField.value) {
                        formData.fieldValues.push({ field: areaIdField.name, value: areaIdField.value });
                    }
                }

                return formData;
            }
        })();
    })();
}
