export function setupProductOverview() {
    (() => {
        if (window.NodeList && !NodeList.prototype.forEach) {
            NodeList.prototype.forEach = Array.prototype.forEach;
        }

        // Used keycodes
        const KEYCODE = {
            SPACE: 32,
            ESC: 27,
            TAB: 9
        };

        // Dialog
        let focusedBefore = document.body;
        let focusableChildren = '';
        let focusableChildrenFirst = '';
        let focusableChildrenLast = '';

        const dialogChange = (element, status) => {
            if (element) {
                if (status === 'open') {
                    element.classList.add('js-active');
                    element.setAttribute('aria-hidden', false);
                } else {
                    element.classList.remove('js-active');
                    element.setAttribute('aria-hidden', true);
                }
            }
        };
        const dialogOpen = (event) => {
            if (!(event.ctrlKey || event.metaKey || event.shiftKey)) {
                event.preventDefault();
            }
            const element = event.target;

            if (element.getAttribute('data-target')) {
                dialogChange(document.getElementById(element.getAttribute('data-target')), 'open');
            } else {
                dialogChange(document.getElementById(element.getAttribute('aria-controls')), 'open');
            }
        };
        const dialogButtonOpen = document.querySelectorAll('[data-action="dialog-open"]');
        dialogButtonOpen.forEach((button) => {
            button.addEventListener('click', dialogOpen);
        });
        const dialogClose = (event) => {
            if (event.target.getAttribute('href')) {
                return
            }

            event.preventDefault();

            const element = event.target;

            if (element.getAttribute('data-target')) {
                dialogChange(document.getElementById(element.getAttribute('data-target')), 'close');
            } else {
                dialogChange(document.getElementById(element.getAttribute('aria-controls')), 'close');
            }
        };
        const dialogButtonClose = document.querySelectorAll('[data-action="dialog-close"]');
        dialogButtonClose.forEach((button) => {
            button.addEventListener('click', dialogClose);
        });

        window.addEventListener('keydown', (event) => {
            const focusedElement = document.activeElement;

            if (event.keyCode === KEYCODE.ESC) {
                // Close dialogs
                const dialogs = document.querySelectorAll('.dialog');
                dialogs.forEach((dialog) => {
                    dialogChange(dialog, 'close');
                });
            }
            if (focusedElement.getAttribute('data-action') === 'dialog-open') {
                if (event.keyCode === KEYCODE.SPACE) {
                    // Open dialog
                    event.preventDefault();
                    dialogChange(document.getElementById(focusedElement.getAttribute('aria-controls')), 'open');
                }
            }
        });
    })();
}
