export function setupVideos() {

    (() => {
        if (window.NodeList && !NodeList.prototype.forEach) {
            NodeList.prototype.forEach = Array.prototype.forEach;
        }

        /**
         * Detect if <template> is supported
         * @return {Boolean}    True or False
         */
        const supportsTemplate = () => {
            return 'content' in document.createElement('template');
        };

        const addResourceHint = (type, url, as) => {
            const resource = document.createElement('link');

            resource.rel = type;
            resource.href = url;

            if (as) {
                resource.as = as;
            }
            if (type === 'preconnect' || as === 'font') {
                resource.crossorigin = true;
            }

            document.head.appendChild(resource);
        };

        // VIDEOS
        let videoPreconnected = false;

        const videoPreload = (element) => {
            if (!videoPreconnected) {
                const videoLink = element.target.closest('[data-action="video-load"]').querySelector('a').href;

                // YOUTUBE ?
                if (videoLink.includes('youtube') || videoLink.includes('youtu.be')) {
                    addResourceHint('dns-prefetch', 'https://www.youtube.com');
                    addResourceHint('preconnect', 'https://www.youtube.com');
                }

                // VIMEO ?
                if (videoLink.includes('vimeo')) {
                    addResourceHint('dns-prefetch', 'https://player.vimeo.com');
                    addResourceHint('preconnect', 'https://player.vimeo.com');
                }

                videoPreconnected = true;
            }
        };

        const videoPlay = (event) => {
            event.preventDefault();

            const element = event.target.closest('a');
            let videoURL = '';

            // Get video ID
            const videoLink = element.href;

            // YOUTUBE ?
            let youtubeID = '';

            if (videoLink.includes('youtube')) {
                if (videoLink.includes('embed')) {
                    const index = videoLink.lastIndexOf('/');
                    youtubeID = videoLink.substring(index + 1);
                } else {
                    const index = videoLink.lastIndexOf('v=');
                    youtubeID = videoLink.substring(index + 2);
                }
            } else if (videoLink.includes('youtu.be')) {
                const index = videoLink.lastIndexOf('/');
                youtubeID = videoLink.substring(index + 1);
            }

            if (youtubeID) {
                videoURL = 'https://www.youtube.com/embed/' + youtubeID + '?autoplay=1&color=20d18e&modestbranding=1&rel=0&showinfo=0';
            }

            // VIMEO ?
            let vimeoID = '';

            if (videoLink.includes('vimeo')) {
                const index = videoLink.lastIndexOf('/');
                vimeoID = videoLink.substring(index + 1);
            }

            if (vimeoID) {
                videoURL = 'https://player.vimeo.com/video/' + vimeoID + '?autoplay=1&byline=0&color=20d18e&portrait=0&title=0';
            }

            const fragment = document.createDocumentFragment();

            if (supportsTemplate()) {
                const itemsClone = document.querySelector('#video').content.cloneNode(true);

                itemsClone.querySelector('iframe').src = videoURL;
                fragment.appendChild(itemsClone);
            } else {
                const itemElement = document.createElement('iframe');
                itemElement.src = videoURL;

                fragment.appendChild(itemElement);
            }

            element.parentNode.querySelector('.embed-responsive').appendChild(fragment);
            element.parentNode.classList.add('js-video-loaded');
        };

        window.addEventListener('CookieInformationConsentGiven', (event) => {
            if (CookieInformation.getConsentGivenFor('cookie_cat_marketing')) {
                const videos = document.querySelectorAll('[data-action="video-load"] a[href]');

                videos.forEach((element) => {
                    element.addEventListener('click', videoPlay, false);
                    element.addEventListener('mouseenter', videoPreload);
                });
            }
        }, false);
    })();
}
